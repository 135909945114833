.response-container {
  display: flex;
  width: 100%;
  // height: 100%;
  margin-top: 10px;
  padding: 10px 10px;
  flex-direction: column;
  background-color: white;

  .hr {
    width: 100%;
    height: 0.7px;
    background-color: var(--lightgrey);
    margin-top: 10px;
    // margin-bottom: 5px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }

  .response {
    display: flex;
    flex-direction: column;

    .border {
      border: 0.7px;
      border-color: var(--lightgrey);
      border-radius: 5px;
    }
  }

  .pretty {
    // If you don't want to show scrollbar - uncomment below lines
    // white-space: pre-wrap;
    // word-break: break-word;

    line-height: 22px;
    font-size: 12px;
    font-weight: 400;
    color: var(--text-dark);

    margin-bottom: -15px;
    margin-top: -15px;
    // background-color: burlywood;
  }

  .raw {
    line-height: 22px;
    font-size: 12px;
    color: var(--text-dark);

    // margin-bottom: -15px;
    // margin-top: -15px;
  }

  .response-error {
    line-height: 22px;
    font-size: 14px;
    font-weight: 400;
    color: red;

    // margin-bottom: -15px;
    // margin-top: -15px;
  }
}
