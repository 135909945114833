.button_control {
  .icon_button {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    border-radius: 5px;
    font-size: smaller;
    color: var(--text-dark);
    background-color: transparent;
    transition: all 150ms ease-in-out;
    &:hover {
      background-color: var(--bg-color-blue);
    }
    &:focus {
      box-shadow: none;
    }
  }
}

.light_background {
  background-color: var(--bg-color-blue);
  border-radius: 5px;
}

.button_tooltip {
  font-size: 12px !important;
}
