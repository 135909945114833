.list_item_share_collection {
  display: flex;
  flex-direction: row;
  align-items: center;
  flex: 1;
  width: 100%;
  background-color: var(--bg-color);
  margin-top: 5px;
  padding: 5px;
  border-radius: 5px !important;
}

.list_item_share_collection:hover {
  background-color: var(--bg-color-blue);
}

.list_item_share_collection:focus {
  background-color: var(--bg-color-blue);
}

.email-input {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-items: stretch;
  width: 50%;
  font-size: 11px;
  // background-color: aqua;
}

.switch-input {
  width: 15%;
  align-content: center;
}
