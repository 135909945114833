:root {
  --primary: #242034;
  --secondary: #d87d2e;
  --secondary-light: #faa75e;

  --bg-color: #f1f4fb; // request and response panel BG
  --bg-color-blue: #e9effc; //sidebar BG
  --bg-color-blue-dark: #d7e3f5; //sidebar logo BG
  --text-dark: #282828;
  --text-light: #ffffff;

  --blue: #0000ff;
  --red: #ff0000;
  --green: #008000;
  --yellow: #ffff00;
  --grey: #808080;
  --lightgrey: #cbcbcb;
  --darkgrey: #424949;
}

// This is for using above color values in JS files
:export {
  primaryColor: var(--primary);
  secondaryColor: var(--secondary);
  textDark: var(--text-dark);
  textLight: var(--text-light);
  grey: var(--grey);
}
