.login-success-container {
  display: flex;
  flex-direction: column;
  width: 100vw;
  height: 100vh;
  // justify-content: center;
  // align-items: center;
  background-color: var(--bg-color-blue);
}

.login-success-header {
  height: 80px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--primary);

  .img-logo {
    -o-object-fit: contain;
    object-fit: contain;
    width: 100px;
    height: auto;
  }
}
