.textarea-wrapper {
  position: relative;
  display: flex;
  width: 100%;
  //---------
  height: auto;
  min-height: 28px;

  .maximum-area {
    height: 100px;
    min-height: 100px;
    line-height: 26px;
    border: solid 0.7px var(--grey);
    border-radius: 4px;
    // background-color: lemonchiffon;
  }
}

.textarea {
  display: flex;
  //---------
  height: auto;
  min-height: 28px;
  line-height: 26px;
  max-height: 80px;
  //---------
  width: 100%;
  font-size: 13px;
  font-weight: 200 !important;
  color: var(--text-dark);
  //-------
  padding: 0 0 0 15px;
  border: none;
  outline: none;
  resize: none;
  // background-color: aquamarine;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 11px;
  }

  .maximum-area {
    height: 100px;
    min-height: 100px;
    line-height: 26px;
    // border: solid 0.7px var(--dark-grey);
    // border-radius: 4px;
    // background-color: lemonchiffon;
  }

  &::placeholder {
    color: #070707;
    font-size: 13px;
    opacity: 0.5;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 11px;
    }
  }

  &:focus {
    font-size: 13px;
    // border: solid 1px var(--grey);
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 11px;
    }
  }

  &_error {
    background-color: #fff3f5;
    border: solid 1px rgba(255, 0, 28, 0.25);
  }
}

.disabled {
  background-color: #eaeaea;
}

.label {
  font-size: 12px;
  margin-bottom: 3px;
  line-height: normal;
  margin-left: 6px;
  display: block;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 11px;
  }
}

.error {
  text-transform: none;
  position: absolute;
  top: calc(100% + 2px);
  font-size: 10px;
  color: red;
}

.isShow {
  &Wrapper {
    position: absolute;
    left: 240px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 580px) {
      left: auto;
      right: 10px;
      top: auto;
      bottom: 8px;
    }
  }

  &Icon {
    font-size: 20px;
    color: #c5c5c5;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: var(--grey);
    }
  }
}

.lg {
  height: 45px; // 42px;
  font-size: 15px;
  @media screen and (max-width: 580px) {
    height: 40px; // 42px;
    font-size: 13px;
  }
}
.sm {
  width: 130px;
}
.xs {
  width: 110px;
}
