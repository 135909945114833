.list_container {
  // display: flex;
  padding: 5px 5px;
  width: 100%;
  margin: 2px 0px;
  justify-content: stretch;
  align-items: stretch;
  background-color: var(--bg-color-blue);
  border-radius: 5px;

  &:hover {
    background-color: var(--bg-color-blue-dark);
  }

  &:focus {
    background-color: var(--bg-color-blue-dark);
  }

  .list_text {
    font-size: 13px;
    font-weight: 400;
    justify-content: stretch;
    align-items: stretch;
  }
}
