@import "react-dyn-tabs/style/react-dyn-tabs.min.css";
@import "react-dyn-tabs/themes/react-dyn-tabs-bootstrap.min.css";

.tabs-outer-container {
  display: "block";
  background: white;
  width: 100%;
}

.tabs-horizontal-container {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  // border-bottom: 0.7px solid var(--lightgrey);
  // background-color: gold;
}

// update style for selected tab view
//(use same classNames as that in imported css file of the library)
.rc-dyn-tabs-tablist
  .rc-dyn-tabs-tab.rc-dyn-tabs-selected
  > .rc-dyn-tabs-title {
  border-width: 2px 1px 1px 1px;
  border-color: var(--secondary) var(--lightgrey) #fff var(--lightgrey);
  background-color: #fff;
  font-size: smaller;
}

.rc-dyn-tabs-tablist .rc-dyn-tabs-tab > .rc-dyn-tabs-title {
  font-size: smaller;
}
