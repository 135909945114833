.dynamic-list-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  // background-color: lightgoldenrodyellow;
  align-items: center;
  border: solid 0.7px var(--grey);
}

.vr {
  width: 1px;
  height: 25px;
  background-color: var(--grey);
}

.check-mark {
  align-self: center;
  width: 5%;
  padding-left: 10px;
  padding-right: 10px;
  padding-top: 2px;
  padding-bottom: 2px;
}

.delete-mark {
  flex: 1;
  align-self: center;
  justify-items: flex-end;
  justify-content: flex-end;
  // text-align: right;
}

.input-wide {
  border-left: solid 0.7px var(--grey);
  display: flex;
  flex-direction: row;
  width: 30%;
  // padding-bottom: 4px;
  // padding-top: 4px;
  align-items: stretch;
  align-self: stretch;
}

.dynamic-list-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  // background-color: lightgoldenrodyellow;
}

.text-wide {
  width: 30%;
  font-size: 11px;
  padding-bottom: 5px;
}

.file-input {
  font-size: 12px;
  margin-left: 5px;
}
