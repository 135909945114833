// Whole screen container
.home-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  // background-color: ;
  overflow-y: hidden;

  .headers-container {
    display: flex;
    flex-direction: row;
    min-height: 50px; // 50px is header height
    height: 50px;
    width: 100vw;
    // background-color: ;
  }

  .full-content-container {
    display: flex;
    flex-direction: row;
    min-height: calc(100vh - 50px); // 50px is header height
    height: calc(100vh - 50px);
    width: 100vw;
    // background-color: ;
  }
}

// Left side logo container
.navbar-brand {
  height: 50px;
  width: 25vw;

  .logo-bg {
    height: 50px;
    margin-right: 2px;
    background-color: var(--primary);
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .logo-img {
    -o-object-fit: contain;
    object-fit: contain;
    // width: 40%;
    width: 25%;
    height: auto;
  }
}

// Left side content container
.sidebar-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 25vw;
  background-color: var(--bg-color-blue);

  .sidepanel-container {
    display: flex;
    flex-direction: row;
    padding-top: 15px;
    height: 100%;
    // background-color: ;

    .toggle-button-container {
      display: flex;
      flex-direction: column;
      width: 20%;
      height: 100%;
      // border-right: 1px solid var(--lightgrey);
    }

    .tree-container {
      display: flex;
      flex-direction: column;
      width: 80%;
      height: 100%;
    }
  }

  .horizontal-container-sidebar {
    display: flex;
    flex-direction: row;
    align-content: center;
    align-items: center;
    border-bottom: 1px solid var(--lightgrey);
  }
}

// Right side content container
.content-container {
  display: flex;
  flex-direction: column;
  height: calc(100vh - 50px);
  width: 75vw;
  // background-color: ;
  overflow-y: auto;

  .inner-content-container {
    display: flex;
    flex-direction: column;
    justify-self: stretch;
    height: calc(100vh - 50px);
    padding: 10px;
    // background-color: ;
  }

  .button-container {
    display: flex;
    flex-direction: row;
    margin: 5px;
    // padding-top: 5px;
    // padding-bottom: 5px;
  }

  .tab-and-env-container {
    display: flex;
    flex-direction: row-reverse;
    background-color: white;
    align-items: center;
    padding-right: 10px;
  }
  .env-button {
    display: flex;
    width: 5%;
  }
}

// Container to show placeholders
.empty {
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  // background-color: ;
}

.curl-popup {
  display: flex;
  flex-direction: column;
}
