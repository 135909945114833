.sidebar-apis-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // background-color: aqua;
}

.sidebar-horizontal-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  justify-content: space-evenly;
  border: none;
  // background-color: lemonchiffon;
}

.sidebar-horizontal-container-2 {
  display: flex;
  flex-direction: row;
  width: 100%;
  align-content: center;
  align-items: center;
  justify-items: stretch;
  justify-content: space-evenly;
  border-bottom: 1px solid var(--lightgrey);
  // background-color: lemonchiffon;
}
