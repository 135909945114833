.dynamic-list-item {
  display: flex;
  flex-direction: row;
  flex: 1;
  align-items: center;
  border: solid 0.7px var(--grey);
  //-----------
  height: auto;
  min-height: max-content;
  width: 100%;
  // background-color: yellowgreen;

  .vr {
    width: 1px;
    height: 25px;
    background-color: var(--grey);
  }

  .check-mark {
    align-self: center;
    width: 5%;
    padding-left: 10px;
    padding-right: 10px;
    padding-top: 2px;
    padding-bottom: 2px;
  }

  .delete-mark {
    flex: 1;
    align-self: center;
    justify-items: flex-end;
    justify-content: flex-end;
    // text-align: right;
  }

  .input-wide {
    align-items: center;
    border-left: solid 0.7px var(--grey);
    // background-color: lemonchiffon;
    width: 30%;
    //-----------
    overflow-y: auto;
    overflow-x: hidden;
    height: auto;
    min-height: max-content;
    //-----------
    position: relative; //**** to put floating-placeholder inside this container

    .floating-placeholder {
      position: absolute; //**** to put this inside input-wide container
      top: 10px; //**** to put this inside input-wide container, with 10px difference from top
      left: 6px;
      //-----------
      font-size: 13px;
      color: #070707;
      opacity: 0.5;
      padding: 0 0 0 15px;
      border: none;
      outline: none;
    }
  }
}

.dynamic-list-header {
  display: flex;
  flex-direction: row;
  flex: 1;
  width: 100%;
  // background-color: lightgoldenrodyellow;
}

.text-wide {
  width: 30%;
  padding-bottom: 5px;
  font-size: 11px;
  font-weight: 300;
  font-family: Montserrat, sans-serif;
}
