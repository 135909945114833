.big_loader {
  width: 100%;
  height: 100vh;
  top: 0;
  left: 0;
  position: fixed;
  z-index: 9999999;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  color: var(--secondary);
  background-color: rgba(0, 0, 0, .4);

  p {
    color: #fff;
  }
}