.listview-dynamic-container {
  display: flex;
  flex: 1;
  width: 100%;
  flex-direction: column;
  padding: 5px;
  line-height: 1.5;
  // background-color: #64a7be;
  align-items: flex-start;
}
