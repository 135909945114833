.environment-container {
  display: flex;
  width: 100%;
  // height: 100%;
  margin-top: 10px;
  padding: 10px 10px;
  flex-direction: column;
  background-color: white;

  .env-title-button-container {
    display: flex;
    width: 100%;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  .env-title {
    // width: 100%;
    padding-left: 5px;
  }

  .collection-title {
    // width: 100%;
    display: flex;
    align-items: center;
    padding: 5px;
    margin-top: 20px;
    font-size: 13px;
    margin-left: 5px;
    border: 0.7px solid var(--grey);
    border-radius: 5px;
  }

  .hr {
    width: 100%;
    height: 0.7px;
    background-color: var(--lightgrey);
    margin-top: 20px;
    // margin-bottom: 5px;
  }

  .empty {
    display: flex;
    flex-direction: column;
    align-items: center;
    // justify-content: center;
  }

  .variable-container {
    min-height: 400px;
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-top: 10px;
  }

  .buttons-horizontal-container {
    margin-top: 10px;
    display: flex;
    flex-direction: row;
    align-items: center;
  }

  .list-title-container {
    width: 100%;
    padding-top: 10px;
    padding-bottom: 10px;
    padding-left: 10px;
    font-size: 13px;
  }
  .list-container {
    width: 100%;
  }
}
