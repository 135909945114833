.data_table {
  .dataTable {
    color: var(--secondary-light) !important;
    -webkit-overflow-scrolling: touch;
    -ms-overflow-style: -ms-autohiding-scrollbar;
    display: block;
    overflow-x: auto;
    width: 100%;
    border: 0;
    &::-webkit-scrollbar {
      height: 10px;
      width: 10px;
    }

    &::-webkit-scrollbar-track {
      box-shadow: none; 
      border-radius: 10px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: #C2CBDF;
      box-shadow: inset 0 0 2px #C2CBDF;
      border-radius: 10px;
    }
  }

  .rdt_TableHeader {
    display: none;
  }

  .rdt_TableCol, .rdt_TableCol_Sortable {
    font-size: 14px;
    font-weight: 600;
    color: #313e6a;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px !important; 
    }
  }

  .rdt_TableCell {
    color: #313e6a;
    font-size: 14px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px !important; 
    }
  }

  .dSYVyP {
    padding-left: 5px;
    padding-right: 5px;
  }
  .sc-eCApnc {
    padding-left: 5px;
    padding-right: 5px;
  }
  .iCxiXr {
    padding-left: 5px;
    padding-right: 5px;
  }
  .cGmWKS {
    padding-left: 5px;
    padding-right: 5px;
  }

  .ezEBKV {
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      min-height: 46px;
    }
  }
  .cchvzS {
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      min-height: 42px;
    }
  }
}