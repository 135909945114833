.sidebar-envs-container {
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  // background-color: aqua;
}

.sidebar-horizontal-container-2 {
  display: flex;
  flex-direction: row;
  align-content: center;
  align-items: center;
  border-bottom: 1px solid var(--lightgrey);
  // background-color: lemonchiffon;
}

.list_text_italic {
  font-size: 13px;
  justify-content: stretch;
  align-items: stretch;
  color: var(--darkgrey);
}
