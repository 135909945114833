.button_control {
  margin: 3px;

  .btn-primary.primal {
    color: var(--text-light) !important;
    background-color: var(--primary);
    border: none !important;
    transition: all 250ms ease-in-out;
    &:hover {
      color: var(--text-dark);
      background-color: var(--secondary);
    }
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.secondary {
    color: var(--text-light) !important;
    background-color: var(--secondary);
    border: none !important;
    // border: 1px solid transparent !important;
    &:hover {
      color: var(--text-light);
      background-color: var(--primary);
      // border: 1px solid var(--text-light) !important;
    }
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.danger {
    color: #fff;
    background-color: red;
    border: 1px solid red;
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.success {
    color: #fff;
    background-color: green;
    border: 1px solid green;
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.grey {
    color: #000;
    background-color: var(--lightgrey);
    border: none;
    &:hover {
      border: none;
      background-color: var(--bg-color-blue-dark);
      box-shadow: none;
    }
  }

  .btn-primary.grey-outline {
    color: #fff;
    background-color: var(--transparent);
    border: 0.8px solid var(--grey);
    color: var(--darkgrey);
    &:hover {
      background-color: var(--bg-color-blue-dark);
      border: 0.8px solid var(--bg-color-blue-dark);
      box-shadow: none;
    }
    &:focus {
      color: var(--darkgrey);
    }
  }

  .btn-primary.disabled {
    color: #fff;
    background-color: var(--lightgrey);
    border: 1px solid var(--lightgrey);
    &:focus {
      box-shadow: none;
    }
  }

  .btn-primary.primary-outline {
    color: var(--text-dark);
    background-color: transparent !important;
    border: 1px solid var(--primary) !important;
    &:hover {
      box-shadow: none;
    }
  }

  .btn-primary.secondary-outline {
    color: var(--text-dark);
    background-color: transparent !important;
    border: 1px solid var(--secondary) !important;
    &:hover {
      color: var(--text-dark);
      border: 1px solid var(--grey) !important;
      box-shadow: none;
    }
  }

  .btn-primary.success-outline {
    color: #fff;
    background-color: var(--transparent);
    border: 2px solid lightgreen;
    color: var(--darkgrey);
    &:hover {
      background-color: rgba($color: lightgreen, $alpha: 0.2);
      border: 2px solid lightgreen;
      box-shadow: none;
    }
    &:focus {
      color: var(--darkgrey);
    }
  }

  .btn-primary.danger-outline {
    color: #fff;
    background-color: var(--transparent);
    border: 1px solid red;
    color: var(--darkgrey);
    &:hover {
      background-color: var(--bg-color-blue-dark);
      border: 1px solid red;
      box-shadow: none;
    }
    &:focus {
      color: var(--darkgrey);
    }
  }

  .btn-primary.smaller {
    font-weight: 600;
    font-size: 10px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 10px;
    }
  }

  .btn-primary.small {
    font-size: 12px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px;
    }
  }

  .btn-primary.medium {
    font-size: 12px;
    width: 100px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 12px;
    }
  }

  .btn-primary.large {
    font-size: 13px;
    width: 160px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 13px;
    }
  }

  .btn-primary.larger {
    font-size: 13px;
    width: 180;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 13px;
    }
  }

  .btn-primary.huge {
    font-size: 13px;
    width: 220px;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 13px;
    }
  }
}

.button_tooltip {
  font-size: 12px !important;
}

.icon_title_container {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}

.icon_title_separator {
  width: 6px;
  height: 6px;
  background-color: transparent;
}

.full-width {
  width: 100%;
}
