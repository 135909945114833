.toggle {
  display: flex;
  flex-direction: column;
  color: var(--text-dark);
  justify-content: center;
  align-items: center;
  padding: 5px;
  border-radius: 5px;
  font-size: xx-small;
  font-weight: 400;

  &:hover {
    color: var(--primary);
    // background-color: var(--lightgrey);
    // font-size: medium;
  }
  &:focus {
    box-shadow: none;
  }
}
