.list_container {
  // display: flex;
  justify-content: stretch;
  align-items: stretch;
  padding: 0px 10px;
  width: 100%;
  // border-radius: 10px;
  // background-color: aqua;
}

// .list_item_light_blue {
//   display: flex;
//   background-color: var(--bg-color);
//   justify-content: flex-start;
//   align-items: flex-start;
//   margin-top: 5px;
//   padding: 5px;
//   border-radius: 5px !important;
// }

// .list_item_light_blue:hover {
//   background-color: var(--bg-color-blue);
// }

// .list_item_light_blue:focus {
//   background-color: var(--bg-color-blue);
// }
