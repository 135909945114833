.highlighter-wrapper {
  width: 100%;
  position: relative;
  margin: 2px 2px;
  // padding: 2px 5px;
  border-radius: 4px;
  border: solid 0.5px var(--grey);
  background-color: var(--white);
  outline: var(--lightgrey);
  // background-color: lemonchiffon;
}

.no-outline {
  border-radius: 0px;
  border-top: none;
  border-left: none;
  border-right: none;
  border-bottom: none;
  // border-bottom: solid 0.5px #000;

  &:focus {
    border-radius: 0px;
    border-top: none;
    border-left: none;
    border-right: none;
    border-bottom: solid 1px var(--darkgrey);
  }
}

//******************************  IMPORTANT NOTE  **********************************************
// These are class names used in the library. Thus we are keeping same name and just updating props.

.DraftEditor-root {
  display: flex;
  flex-direction: row;
}

.public-DraftEditorPlaceholder-inner {
  display: none; //hide placeholder label
  min-width: 100px;
  height: 28px;
  line-height: 28px;
  padding: 0 0 0 15px;
  color: var(--text-dark);
  font-size: 13px;
  opacity: 0.5;
  font-weight: 200 !important;
  background-color: skyblue;
}

.DraftEditor-editorContainer {
  // height: 28px;
  // line-height: 28px;
  //-------
  height: auto;
  min-height: 28px;
  line-height: 26px;
  max-height: 80px;
  //----------
  width: 100%;
  // background-color: lemonchiffon;
}

.public-DraftEditor-content {
  // height: 28px;
  // line-height: 28px;
  //-------
  height: auto;
  min-height: 28px;
  line-height: 26px;
  max-height: 80px;
  //----------
  width: 100%;
  font-size: 13px;
  font-weight: 200 !important;
  color: var(--text-dark);
  //----------
  padding: 0 0 0 15px;
  text-overflow: ellipsis;
  border-radius: 4px;
  border: none;
  outline: none;
  align-content: center;
  // background-color: lightsalmon;

  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 11px;
  }

  &::placeholder {
    color: #070707;
    font-size: 13px;
    opacity: 0.5;
    @media only screen and (max-width: 1400px) and (min-width: 1200px) {
      font-size: 11px;
    }
  }
}

//************************************************************************

.disabled {
  background-color: var(--bg-color-blue) !important;
}

.label {
  font-size: 12px;
  margin-bottom: 3px;
  line-height: normal;
  margin-left: 6px;
  display: block;
  @media only screen and (max-width: 1400px) and (min-width: 1200px) {
    font-size: 11px;
  }
}

.error {
  text-transform: none;
  position: absolute;
  top: calc(100% + 2px);
  font-size: 10px;
  color: red;
}

.isShow {
  &Wrapper {
    position: absolute;
    left: 240px;
    top: 0;
    bottom: 0;
    display: flex;
    align-items: center;
    @media screen and (max-width: 580px) {
      left: auto;
      right: 10px;
      top: auto;
      bottom: 8px;
    }
  }

  &Icon {
    font-size: 20px;
    color: #c5c5c5;
    cursor: pointer;
    transition: color 0.3s;

    &:hover {
      color: var(--grey);
    }
  }
}

.lg {
  height: 45px; // 42px;
  font-size: 15px;
  @media screen and (max-width: 580px) {
    height: 40px; // 42px;
    font-size: 13px;
  }
}
.sm {
  width: 130px;
}
.xs {
  width: 110px;
}
