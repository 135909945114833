.tree_node {
  display: flex;
  color: aqua;
  font-size: medium;
  background-color: transparent;
  padding: 5px;
  &:hover {
    color: var(--primary);
    background-color: var(--lightgrey);
  }
}
