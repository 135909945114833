.request-container {
  width: 100%;
  // height: 100%;
  display: flex;
  flex-direction: column;
  padding-top: 5px;
  // align-items: center;
  // justify-content: center;
  border-top: 0.7px solid var(--lightgrey);
  background-color: white;

  .horizontal-container {
    display: flex;
    flex-direction: row;
    justify-content: stretch;
    align-items: center;
    height: fit-content;
    padding-right: 5px;
    width: 100%;
  }

  .horizontal-container-small {
    display: flex;
    flex-direction: row;
    padding-right: 5px;
  }

  .red-circle {
    width: 10px;
    height: 10px;
    border-radius: 5px;
    background-color: red;
    align-self: center;
    margin-right: 10px;
  }

  .input-box {
    width: 100%;
    display: flex;
    overflow: hidden;
    align-self: center;
    // margin-top: 8px;
    // background-color: aqua;
    position: relative; //**** to put floating-placeholder inside this container

    .floating-placeholder {
      position: absolute; //**** to put this inside input-box container
      top: 10px; //**** to put this inside input-box container, with 10px difference from top
      font-size: 13px;
      color: #070707;
      opacity: 0.8;
      padding: 0 0 0 15px;
      border: none;
      outline: none;
    }
  }

  .input-box > input {
    width: 100%;
  }
}
